import { Instance } from "./index";
import { unstable_cache } from 'next/cache'
import { readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

export const getManausActivities = async (langCode = 'en-US') => {
    return await getManausActivities_cached(langCode);
}
const getManausActivitiesRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const items = await getDynamicContentRequest(langCode, 'manausamazon_activities');
            resolve(items);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getManausActivities_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getManausActivitiesRequest(langCode);
    },
    ['manausamazon_activities'],
    { revalidate: revalidateTime, tags: ['cached_request', 'manausamazon_activities'] }
);

export const getManausInfoItems = async (langCode = 'en-US') => {
    return await getManausInfoItems_cached(langCode);
}
const getManausInfoItemsRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const items = await getDynamicContentRequest(langCode, 'manausamazon_info_items');
            resolve(items);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getManausInfoItems_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getManausInfoItemsRequest(langCode);
    },
    ['manausamazon_info_items'],
    { revalidate: revalidateTime, tags: ['cached_request', 'manausamazon_info_items'] }
);

export const getManausAmazonItems = async (langCode = 'en-US') => {
    return await getManausAmazonItems_cached(langCode);
}
const getManausAmazonItemsRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const items = await getDynamicContentRequest(langCode, 'manausamazon_amazon_items');
            resolve(items);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getManausAmazonItems_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getManausAmazonItemsRequest(langCode);
    },
    ['manausamazon_amazon_items'],
    { revalidate: revalidateTime, tags: ['cached_request', 'manausamazon_amazon_items'] }
);

const getDynamicContentRequest = async (langCode = 'en-US', type) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepFilterObject = {
                translations: {
                    _filter: {
                        _and: [
                            {
                                languages_code: { _eq: langCode },
                            },
                        ],
                    },
                }
            }

            const filter = {
                status: {
                    _eq: 'published'
                },
            }

            const fields = [
                '*',
                {
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                    ],
                },
            ];

            const dynamicContentItems = await Instance.request(readItems(type, {
                deep: deepFilterObject,
                filter: filter,
                fields: fields,
                sort: ['sort', 'date_created'],
            }));

            if (!dynamicContentItems) {
                resolve(null);
            } else {
                dynamicContentItems.map((dynamicContentItem) => {
                    if (dynamicContentItem) {
                        dynamicContentItem.translated_content = dynamicContentItem.translations[0];

                        delete dynamicContentItem.translations;
                    }

                    return dynamicContentItem;
                });

                resolve(dynamicContentItems);
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}


export const getManausActivityData = async (langCode = 'en-US', id) => {
    return await getManausActivityData_cached(langCode, id);
}
const getManausActivityDataRequest = async (langCode = 'en-US', id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const item = await getDynamicContentDataRequest(langCode, 'manausamazon_activities', id);
            resolve(item);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getManausActivityData_cached = unstable_cache(
    async (langCode = 'en-US', id) => {
        return await getManausActivityDataRequest(langCode, id);
    },
    ['manausamazon_activity_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'manausamazon_activity_data'] }
);

export const getManausInfoItemData = async (langCode = 'en-US', id) => {
    return await getManausInfoItemData_cached(langCode, id);
}
const getManausInfoItemDataRequest = async (langCode = 'en-US', id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const item = await getDynamicContentDataRequest(langCode, 'manausamazon_info_items', id);
            resolve(item);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getManausInfoItemData_cached = unstable_cache(
    async (langCode = 'en-US', id) => {
        return await getManausInfoItemDataRequest(langCode, id);
    },
    ['manausamazon_info_item_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'manausamazon_info_item_data'] }
);

export const getManausAmazonItemData = async (langCode = 'en-US', id) => {
    return await getManausAmazonItemData_cached(langCode, id);
}
const getManausAmazonItemDataRequest = async (langCode = 'en-US', id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const item = await getDynamicContentDataRequest(langCode, 'manausamazon_amazon_items', id);
            resolve(item);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getManausAmazonItemData_cached = unstable_cache(
    async (langCode = 'en-US', id) => {
        return await getManausAmazonItemDataRequest(langCode, id);
    },
    ['manausamazon_amazon_item_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'manausamazon_amazon_item_data'] }
);

const getDynamicContentDataRequest = async (langCode = 'en-US', type, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                content_blocks: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                    seo: {
                        status: {
                            _eq: 'published',
                        },
                        translations: deepLangFilterObj,
                    },
                },
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _eq: id,
                }
            };
    
            const fields = [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                            ]
                        }
                    ],
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                    ],
                    content_blocks: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*',
                            ],
                        },
                    ],
                },
            ];
    
            const items = await Instance.request(readItems(type, {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!items) {
                resolve(null);
            } else {
                const item = items[0];
    
                if (item) {
                    item.translated_content = item.translations[0];
                    delete item.translations;
    
                    if (item.seo) {
                        item.seo.translated_content = item.seo.translations[0];
                        delete item.seo.translations;
                    }
    
                    if (item.content_blocks) {
                        item.content_blocks.map((content_block) => {
                            content_block.translated_content = content_block.translations[0];
    
                            delete content_block.translations;
    
                            return content_block;
                        });
                    }
    
                    resolve(item);
                } else {
                    resolve(null);
                }
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
