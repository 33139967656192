'use client';

import React, { useState, useEffect } from 'react';

import { tryCatchCreateElement } from '@/helpers/component_helper'
import { getFileUrl, getTranslationFunction, getFormFetcher } from '@/app/utils/mixed_csr_ssr';
import { clsx } from 'clsx';
import { createEntry, createOrder } from '@/app/utils/directus';
import { useSearchParams } from 'next/navigation'
import { useReCaptcha } from "next-recaptcha-v3";

import * as lucideIcons from 'lucide-react';
import Container from '@/components/shared/Container';
import Image from 'next/image';
import Typography from '@/components/shared/Typography';
import Button from '@/components/shared/forms/Button';
import Input from '@/components/shared/forms/Input';
import Textarea from '@/components/shared/forms/Textarea';
import Select from '@/components/shared/forms/Select';

const FormNew = ({ params, form_key, linked_item_id = null, className }) => {
    const { executeRecaptcha } = useReCaptcha();

    const { config, locale, langCode } = params;
    const { settings, toursData, cruisesData } = config;
    const { t } = getTranslationFunction(config, locale);
    const { getFormByKey } = getFormFetcher(config, locale);

    const form = getFormByKey(form_key);

    const [data, setData] = useState({
        slug_locale: locale,
        locale: langCode
    });
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [tours, setTours] = useState(null);
    const [cruises, setCruises] = useState(null);

    const [toursInputVisible, setToursInputVisible] = useState(false);
    const [cruisesInputVisible, setCruisesInputVisible] = useState(false);

    const searchParams = useSearchParams()
    const infoBase64 = searchParams.get('info');
    const info = infoBase64 ? JSON.parse(atob(infoBase64)) : {};

    const [totalPrice, setTotalPrice] = useState(0);
    const [subtotalPrice, setSubtotalPrice] = useState(0);
    const [showDiscount, setShowDiscount] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const tempData = {};

        if (form.name === 'booking') {
            setCruises(cruisesData.map((option) => {
                return {
                    ...option.translated_content,
                    ...option,
                    label: option.translated_content.title,
                    value: option.id
                };
            }));
        } else if (form.name === 'tour-booking') {
            setTours(toursData.map((option) => {
                return {
                    ...option.translated_content,
                    ...option,
                    label: option.translated_content.title,
                    value: option.id
                };
            }));
        } else if (form.key.includes('brochure')) {
            if (form.key.includes('-brochure')) {
                if (linked_item_id) {
                    tempData.linked_item_id = linked_item_id;
                }

                if (form.key.includes('tour')) {
                    tempData.linked_item = 'tours';
                } else if (form.key.includes('cruise')) {
                    tempData.linked_item = 'cruises';
                }
            }
            
            tempData.type = form.name;

            setData({
                ...data,
                ...tempData
            });
        }

    }, [form, toursData, cruisesData]);

    useEffect(() => {
        const tempData = {};

        if (form.name === 'booking') {

            setToursInputVisible(false);
            setCruisesInputVisible(true);

            tempData.linked_item = 'cruises';

            if (info) {
                if (info['item-id']) {
                    tempData.linked_item_id = info['item-id'];
                }
            }
            
            setData({
                ...data,
                ...tempData
            });
        } else if (form.name === 'tour-booking') {

            setToursInputVisible(true);
            setCruisesInputVisible(false);

            tempData.linked_item = 'tours';

            if (info) {
                if (info['item-id']) {
                    tempData.linked_item_id = info['item-id'];
                }
            }
            
            setData({
                ...data,
                ...tempData
            });
        }

    }, [tours, cruises]);

    useEffect(() => {
        if (form.name === 'tour-booking') {
            if (data.linked_item_id) {
                const tour = tours.find((tourLoop) => tourLoop.id === parseInt(data.linked_item_id));
                if (tour) {
                    const price = parseFloat(tour.eur_price ?? 0);

                    if(!data.number_of_people) {
                        setSubtotalPrice(price);
                        setTotalPrice(price);
                        setShowDiscount(false);
                    }

                    if (price > 0) {
                        if (parseInt(data.number_of_people) >= 4) {
                            setSubtotalPrice(price);
                            setTotalPrice(price * 0.9);
                            setShowDiscount(true);
                        } else {
                            setSubtotalPrice(price);
                            setTotalPrice(price);
                            setShowDiscount(false);
                        }
                    } else {
                        setSubtotalPrice(0);
                        setTotalPrice(0);
                        setShowDiscount(false);
                    }
                }
            }
        }
    }, [data]);

    async function validateRecaptcha (token) {
        return new Promise(async (resolve, reject) => {
            try {
                const googleResponse = await fetch('/api/recaptcha', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token: token
                    })
                });
        
                const googleData = await googleResponse.json();
                if (!googleData.success) {
                    reject();
                }

                resolve(true);
            } catch (error) {
                console.error('Captcha Form error', error);
                reject();
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        const allFields = e.target.querySelectorAll('input, select, textarea');
        for (const field of allFields) {
            if (field.required) {
                const fieldElementType = field.tagName;
                switch (fieldElementType) {
                    case "SELECT":
                        if (field.value === 'select' || !field.value) {
                            setError(t('form_error'));
                            return;
                        }
                        break;
                    case "INPUT":
                        if (field.type === 'checkbox') {
                            if (!field.checked) {
                                setError(t('form_error'));
                                return;
                            }
                        } else if (!field.value) {
                            setError(t('form_error'));
                            return;
                        }
                        break;
                    case "TEXTAREA":
                        if (!field.value) {
                            setError(t('form_error'));
                            return;
                        }
                        break;
                    default:
                        if (!field.value) {
                            setError(t('form_error'));
                            return;
                        }
                        break;
                }
            }
        }

        executeRecaptcha("form_submit").then((token) => {
            validateRecaptcha(token).then(() => {
                console.log('isValid');

                if (form.name === 'tour-booking') {
                    // Create Order
                    createOrder(e.target.id, data, data.linked_item_id, params, token).then((res) => {
                        if (typeof res === 'object') {
                            if (res.success) {
                                if (res.data) {
                                    if (res.data.payment && res.data.payment.payment_url) {
                                        setSuccess(t('form_success'));
                                        setLoading(false);
                                        e.target.reset();

                                        // Redirect to payment url
                                        window.location.href = res.data.payment.payment_url;
                                    } else {
                                        console.warn('No payment url in successful response');
                                        setError(t('form_error'));
                                        setLoading(false);
                                    }
                                } else {
                                    setError(t('form_error'));
                                    setLoading(false);
                                    console.warn('No data in successful response');
                                }
                            } else {
                                console.error('Create Order error', res.message);
                                setError(t('form_error'));
                                setLoading(false);
                            }
                        } else {
                            setSuccess(res);
                            setLoading(false);
                            e.target.reset();
                        }
                    }).catch((err) => {
                        console.error('Create Order error', err);
                        setError(t('form_error'));
                        setLoading(false);
                    });
                } else {
                    if (data.linked_item) {
                        // Create Entry
                        createEntry(e.target.id, data, data.linked_item, data.linked_item_id).then((res) => {
                            setLoading(false);
                            setSuccess(t(res));
            
                            e.target.reset();
                        }).catch((err) => {
                            console.error('Create Entry error', err);
                            setError(t('form_error'));
                            setLoading(false);
                        });
                    } else {
                        // Create Entry
                        createEntry(e.target.id, data, info.collection, info['item-id']).then((res) => {
                            setLoading(false);
                            setSuccess(t(res));
            
                            e.target.reset();
                        }).catch((err) => {
                            console.error('Create Entry error', err);
                            setError(t('form_error'));
                            setLoading(false);
                        });
                    }
                }

                setLoading(false);
            }).catch((err) => {
                console.error('Captcha Form error', err);
                setError(t('form_error'));
                setLoading(false);
            });
        }).catch((err) => {
            console.error('Captcha Form error', err);
            setError(t('form_error'));
            setLoading(false);
        });
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    if (!form) {
        return null;
    }
    
    return (
        <>
            <Container className={clsx('-mt-44 z-10 relative scroll-mt-40 pb-20', className)} id={`request-${form.key}`}>
                <form id={form.id} onSubmit={handleSubmit} className=' lg:w-2/3 mx-auto rounded-4xl bg-secondary-100 flex flex-col gap-y-8 p-8 items-center relative'>
                    <div className='w-28 h-28 -mt-20'>
                        {settings.logo_no_text && (
                            <Image className="w-auto h-full mx-auto" src={getFileUrl(settings.logo_no_text)} alt={'Logo'} width={200} height={50} />
                        )}
                    </div>

                    {!form.translated_content?.title && !form.translated_content?.short_description ? null : (
                        <div className='flex flex-col items-center'>
                            {form.translated_content.title && (
                                <Typography variant='h3' className='w-fit uppercase text-primary-800 font-bold'>
                                    {form.translated_content?.title}
                                </Typography>
                            )}

                            {form.translated_content?.short_description && (
                                <Typography variant='p' className='w-fit max-w-lg text-primary-800 text-center'>
                                    {form.translated_content?.short_description}
                                </Typography>
                            )}
                        </div>
                    )}

                    {form.groups && form.groups.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                            <div className={`w-full flex flex-col ${group.data.group_is_hidden ? 'hidden' : ''}`}>
                                {(group.data.translated_content && group.data.translated_content.label && form.show_group_labels) && (
                                    <Typography variant='p' className='w-fit text-primary-800'>
                                        {group.data.translated_content.label}
                                    </Typography>
                                )}

                                <div className='w-full bg-primary-800 rounded-md p-2 '>
                                    {group.data.fields && group.data.fields.map((field, fieldIndex) => {
                                        const isLastField = fieldIndex == group.data.fields.length - 1;
                                        const isBeforeLastField = fieldIndex == group.data.fields.length - 2;

                                        let classname = `border-secondary-100 w-full`

                                        if (field.data.width === 'full') {
                                            classname += ` border-b last:border-b-0`
                                        } else { // half
                                            classname += ` lg:w-1/2`
                                            if (isLastField || isBeforeLastField) {
                                                if (isLastField) {
                                                    classname += ' border-b-0'
                                                } else if (isBeforeLastField) {
                                                    // check if last field is full width or not
                                                    if (group.data.fields[fieldIndex + 1].data.width === 'full') {
                                                        classname += ' border-b'
                                                    } else {
                                                        classname += ' border-b lg:border-b-0'
                                                    }
                                                }
                                            } else {
                                                classname += ' border-b'
                                            }
                                        }

                                        const spreadProps = {}
                                        if (field.data.readonly) {
                                            spreadProps.readOnly = true
                                            spreadProps.disabled = true

                                            if (field.data.key === 'info' && info) {
                                                if(field.data.type && field.data.type !== 'dropdown' && field.data.type !== 'textarea') {
                                                    spreadProps.value = JSON.stringify(info)
                                                }
                                            }
                                        }

                                        return (
                                            <React.Fragment key={fieldIndex}>
                                                {(field.data.type && field.data.type == 'textarea') ? (
                                                    <Textarea {...spreadProps} required={field.data.required} onChange={handleChange} name={field.data.key} rows={field.data.row_count} placeholder={field.data.translated_content?.placeholder ?? ''} className={classname} />
                                                ) : (field.data.type && field.data.type == 'dropdown') ? (
                                                    field.data.key === 'linked_item' ? (
                                                        <>
                                                            {toursInputVisible && (
                                                                <Select {...spreadProps} value={info && info['item-id'] ? info['item-id'] : 'select'} required={field.data.required} onChange={handleChange} name='linked_item_id' options={tours} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />
                                                            )}

                                                            {cruisesInputVisible && (
                                                                <Select {...spreadProps} value={info && info['item-id'] ? info['item-id'] : 'select'} required={field.data.required} onChange={handleChange} name='linked_item_id' options={cruises} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Select {...spreadProps} required={field.data.required} onChange={handleChange} name={field.data.key} options={field.data.options ?? []} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />
                                                    )
                                                ) : (
                                                    <Input {...spreadProps} required={field.data.required} onChange={handleChange} name={field.data.key} placeholder={field.data.translated_content?.placeholder ?? ''} type={field.data.type ?? 'text'} className={classname} />
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                                {(groupIndex == form.groups.length - 1) && (
                                    <>
                                        {form.name === 'tour-booking' && (
                                            <>
                                                <div className='w-full mt-4 flex flex-col gap-2'>
                                                    {showDiscount && (
                                                        <>
                                                            <Typography variant='h6' className='w-fit text-primary-800'>
                                                                {t('subtotal_price')}: {subtotalPrice.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}
                                                            </Typography>
                                                            <Typography variant='p' className='w-fit text-primary-800'>
                                                                -10% {t('discount_price_notice')}
                                                            </Typography>
                                                        </>
                                                    )}
                                                </div>
                                                <div className='w-full mt-4 flex gap-x-2'>
                                                    <Typography variant='h6' className='w-fit text-primary-800'>
                                                        {t('total_price')}: {totalPrice.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}
                                                    </Typography>
                                                </div>
                                            </>
                                        )}
                                        {(success || error) && (
                                            <div>
                                                {success && (
                                                    <Typography variant='p' className='text-green-500 font-semibold'>
                                                        {success}
                                                    </Typography>
                                                )}
                        
                                                {error && (
                                                    <Typography variant='p' className='text-red-500 font-semibold'>
                                                        {error}
                                                    </Typography>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                
                            </div>
                        </React.Fragment>
                    ))}

                    {form.require_consent_checkbox == true && (
                        <div className='w-full'>
                            <Input name='consent_checkbox' id='consent_checkbox' required={true} type='checkbox' label={t('consent_checkbox_description')} params={params} />
                        </div>
                    )}

                    {/* <pre>
                        {JSON.stringify(data, null, 2)}
                    </pre> */}

                    <div>
                        <Button type='submit' variant={`${form.button_variant ? form.button_variant : 'tertiary'}`}>
                            {form && form.translated_content && form.translated_content.button_text ? form.translated_content.button_text : 'Submit'}
                        </Button>
                    </div>
                        
                    {loading && (
                        <div className="absolute inset-0 bg-secondary-100 opacity-50 rounded-4xl flex items-center justify-center z-10">
                            <div className="animate-spin w-fit h-fit">
                                {tryCatchCreateElement(lucideIcons['Loader'], { size: 32 })}
                            </div>
                        </div>
                    )}
                </form>
            </Container>
        </>
    );
}

export default FormNew;