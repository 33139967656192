import React from 'react'
import * as lucideIcons from 'lucide-react'
import { tryCatchCreateElement } from '@/helpers/component_helper'

export default function FilledHexagonIcon({
	className = '',
	iconName = '',
	iconClassName = '',
    iconSize = 24,
    fill = 'secondary-100'
}) {
	return (
		<>
			<div className={`relative ${className}`}>
				<svg className='absolute inset-0 w-full h-full' width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0L31.5885 9V27L16 36L0.411543 27V9L16 0Z" fill={`var(--color-${fill})`} />
				</svg>

                {iconName && (
                    <div className={`absolute inset-0 flex items-center justify-center text-primary-800 ${iconClassName}`}>
                        {tryCatchCreateElement(lucideIcons[iconName], { size: iconSize })}
                    </div>
                )}
			</div>
		</>
	)
}
