'use client';

import React from 'react';
import { clsx } from 'clsx';

import Link from 'next/link'
import ButtonArrowLeft from '@/components/shared/icons/ButtonArrowLeft';
import ButtonArrowRight from '@/components/shared/icons/ButtonArrowRight';

export default function LinkButton({ children, variant = 'primary', href, id, className = '', wrapperClassName = '', style, ...rest }) {

	if (
		variant !== 'primary' && variant !== 'primary-bordered' &&
		variant !== 'secondary' && variant !== 'secondary-bordered' &&
		variant !== 'tertiary' && variant !== 'tertiary-bordered'
	) {
		console.error('Invalid variant for Button component. Please use one of the following: primary, primary-bordered, secondary, secondary-bordered, tertiary, tertiary-bordered.');
		return null;
	}

	let defaultClasses = 'relative z-10 w-fit font-overpass text-base shadow-button uppercase pb-2 pt-2-plus-2px px-6 outline-none focus:outline-none';
	let svg = {
		stroke: '',
		fill: ''
	};
	switch (variant) {
		case 'primary':
			defaultClasses += ' text-white bg-primary-800 border-2 border-primary-800';
			svg.fill = 'primary-800';
			break;
		case 'primary-bordered':
			defaultClasses += ' text-primary-800 bg-transparent border-2 border-primary-800';
			svg.stroke = 'primary-800';
			break;
		case 'secondary':
			defaultClasses += ' text-primary-800 bg-secondary-100 border-2 border-secondary-100';
			svg.fill = 'secondary-100';
			break;
		case 'secondary-bordered':
			defaultClasses += ' text-secondary-100 bg-transparent border-2 border-secondary-100';
			svg.stroke = 'secondary-100';
			break;
		case 'tertiary':
			defaultClasses += ' text-white bg-tertiary-500 border-2 border-tertiary-500';
			svg.fill = 'tertiary-500';
			break;
		case 'tertiary-bordered':
			defaultClasses += ' text-tertiary-500 bg-transparent border-2 border-tertiary-500';
			svg.stroke = 'tertiary-500';
			break;
		default:
			break;
	}

	return (
		<div className={clsx('button-wrapper flex items-center', wrapperClassName)}>
			<ButtonArrowLeft {...svg} />

			<Link
				href={href}
				id={id}
				className={clsx(defaultClasses, className)}
				style={style}
				{...rest}
			>
				{children}
			</Link>

			<ButtonArrowRight {...svg} />
		</div>
	)
}
