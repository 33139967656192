import { Instance } from "./index";
import { unstable_cache } from 'next/cache'
import { readItems, createItem } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

export const getForms = async (langCode = 'en-US') => {
    return await getForms_cached(langCode);
}
export const getFormsRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                groups: {
                    translations: deepLangFilterObj,
                    status: {
                        _eq: 'published',
                    },
                    form_groups_id: {
                        translations: deepLangFilterObj,
                        status: {
                            _eq: 'published',
                        },
                        fields: {
                            fields_id: {
                                translations: deepLangFilterObj,
                                status: {
                                    _eq: 'published',
                                }
                            }
                        }
                    }
                }
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                }
            };
    
            const fields = [
                '*',
                {
                    translations: [
                        '*'
                    ],
                    groups: [
                        '*',
                        {
                            form_groups_id: [
                                '*',
                                {
                                    translations: [
                                        '*'
                                    ],
                                    fields: [
                                        '*',
                                        {
                                            fields_id: [
                                                '*',
                                                {
                                                    translations: [
                                                        '*'
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ];
    
            const forms = await Instance.request(readItems('forms', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!forms) {
                resolve(null);
            } else {
                resolve(forms.map((form) => {
                    if (form) {
                        form.translated_content = form.translations[0];
                        delete form.translations;
                    }
    
                    form.groups.map((group) => {
                        group.union_id = group.id;
                        delete group.id;
                        group.data = group.form_groups_id;
                        delete group.form_groups_id;
    
                        if (group.data.translations) {
                            group.data.translated_content = group.data.translations[0];
                            delete group.data.translations;
                        }
    
                        group.data.fields.map((field) => {
                            field.union_id = field.id;
                            delete field.id;
                            field.data = field.fields_id;
                            delete field.fields_id;
        
                            if (field.data.translations) {
                                field.data.translated_content = field.data.translations[0];
                                delete field.data.translations;
                            }
                        });
                    });
    
                    return form;
                }));
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getForms_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getFormsRequest(langCode);
    },
    ['forms'],
    { revalidate: revalidateTime, tags: ['cached_request', 'forms'] }
);

export const createEntry = async (formId, data, collection, itemId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let collectionArray = null;
            if (collection && itemId) {
                collectionArray = [
                    {
                        item: itemId + '',
                        collection: collection
                    }
                ];
            }

            const response = await Instance.request(createItem('form_entries', {
                form_id: formId,
                data: data,
                collection: collectionArray
            }));

            if (response) {
                resolve('form_success');
            } else {
                reject('form_error');
            }
        } catch (error) {
            console.error('Form entry creation error', error);
            reject('form_error');
        }
    });
}
export const createOrder = async (formId, data, itemId) => {
    return new Promise(async (resolve, reject) => {
        data.form_key = formId;

        try {
            const createOrderResponse = await fetch('/api/orders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    item_id: itemId,
                    data: data,
                })
            });

            const createOrderData = await createOrderResponse.json();
            if(!createOrderData.success) {
                reject('form_error');
            } else {
                resolve({
                    success: true,
                    data: createOrderData.data,
                    message: 'form_success'
                })
            }
        } catch (error) {
            console.error('Booking creation error', error);
            reject('form_error');
        }
    });
}
