import { Instance } from "./index";
import { unstable_cache } from 'next/cache'
import { readCollections, readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

// ---------------- Start Pathname Functions ----------------
export const getPathnames = async (cache = true, langCode = 'en-US') => {
    const staticPathnames = await getStaticPathnames(cache, langCode);
    const dynamicPathnames = await getDynamicPathnames(langCode);

    const combinedPathnames = [...staticPathnames, ...dynamicPathnames];
    
    return combinedPathnames;
}

export const createUsablePathnamesObject = async (pathnames, settings = null, languages = null) => {
    if (!settings || !languages || !pathnames) {
        console.error('createUsablePathnamesObject: Missing required parameters');
        return null;
    }

    const pathnamesWithInfo = {
        pathnames: {},
        pathnamesWithInfo: {},
        pathnameSearchArray: [],
    };

    for await (const pathname of pathnames) {
        const pagePathnames = {};
        const pagePathnamesWithInfo = {
            ...pathname,
            translated_content: pathname.translated_content ?? null,
            collection: pathname.collection ?? null,
            pathnames: {},
        }
        
        // console.log(`Now processing pathname ${pathname.slug} (collection: ${pathname.collection}) ${pathname.translations.length} translations`);

        for await (const language of languages) {
            const slugCode = language.slug_code;

            const matchingTranslation = pathname.translations.find((translation) => {
                if (!translation.languages_code) {
                    return false;
                }

                if (!translation.languages_code.slug_code) {
                    return false;
                }

                return translation.languages_code.slug_code === slugCode;
            });

            if (matchingTranslation) {
                pagePathnames[slugCode] = matchingTranslation.slug;
                pagePathnamesWithInfo.pathnames[slugCode] = matchingTranslation.slug;
            }
        }

        pathnamesWithInfo.pathnames[pathname.slug] = pagePathnames;
        pathnamesWithInfo.pathnamesWithInfo[pathname.slug] = pagePathnamesWithInfo;
    }

    pathnamesWithInfo.pathnameSearchArray = Object.values(pathnamesWithInfo.pathnamesWithInfo);

    return pathnamesWithInfo;
}

// ---------------- Start Static Page Collections ----------------
const getStaticPageCollections_cached = unstable_cache(
    async () => {
        return await getStaticPageCollectionsRequest();
    },
    ['static_page_collections'],
    { revalidate: revalidateTime, tags: ['cached_request', 'static_page_collections'] }
);
const getStaticPageCollectionsRequest = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const staticPageCollections = await Instance.request(readCollections());
            resolve(staticPageCollections);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
// ---------------- End Static Page Collections ----------------

// ---------------- Start Static Page Request ----------------
const getStaticPageRequest = async (collection = '', fields = ['*']) => {
    return new Promise(async (resolve, reject) => {
        try {
            const staticPage = await Instance.request(readItems(collection, {
                fields: fields,
                limit: -1
            }));
            resolve(staticPage);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
// ---------------- End Static Page Request ----------------

// ---------------- Start Dynamic Page Request ----------------
const getDynamicPageItemsRequest = async (collection = '', fields = ['*']) => {
    return new Promise(async (resolve, reject) => {
        try {
            const dynamicPageItems = await Instance.request(readItems(collection, {
                fields: fields,
                filter: {
                    status: {
                        _eq: 'published',
                    }
                },
                limit: -1
            }));
            resolve(dynamicPageItems);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
// ---------------- End Dynamic Page Request ----------------




const getStaticPathnames = async (cache = true, langCode = 'en-US') => {
    const staticPathnames = [];
    
    let allCollections = null;
    if (cache) {
        allCollections = await getStaticPageCollections_cached();
    } else {
        allCollections = await getStaticPageCollectionsRequest();
    }
    
    let staticPageCollections = null;
    if (allCollections) {
        staticPageCollections = allCollections.filter((collection) => {
            if (collection.meta.collection_type === 'static_page' && collection.meta.singleton) {
                return collection;
            } else {
                return null;
            }
        });

        if (Object.keys(staticPageCollections).length > 0) {
            const staticPageFields = [
                'id',
                'slug',
                'parent_slug',
                'translations.slug',
                'translations.page_name',
                'translations.title',
                'translations.languages_code.slug_code',
                'translations.languages_code.code',
            ]

            for await (const collection of staticPageCollections) {
                const staticPage = await getStaticPageRequest(collection.collection, staticPageFields);
                if (staticPage) {
                    const matchingTranslation = staticPage.translations.find((translation) => {
                        if (!translation.languages_code) {
                            return false;
                        }
    
                        return translation.languages_code.code === langCode;
                    });

                    staticPathnames.push({
                        ...staticPage,
                        collection: collection.collection,
                        isStatic: true,
                        isDynamic: false,
                        parent_slug: staticPage.parent_slug ?? null,
                        translated_content: matchingTranslation ?? null,
                    });
                    
                } else {
                    console.error('Error fetching static page', collection.collection);
                }
            }
        }
    }

    return staticPathnames;
}

const getDynamicPathnames = async (langCode = 'en-US') => {
    const dynamicPathnames = [];
    const dynamicPageFields = [
        'id',
        'slug',
        'parent_slug',
        'translations.slug',
        'translations.page_name',
        'translations.title',
        'translations.languages_code.slug_code',
        'translations.languages_code.code',
    ]
    const dynamicCollections = [
        {
            'collection': 'cruises',
            'pageCollection': 'cruise_page',
        },
        {
            'collection': 'tours_categories',
            'pageCollection': 'tours_category_page',
        },
        {
            'collection': 'tours',
            'pageCollection': 'tour_page',
        },
        {
            'collection': 'boats',
            'pageCollection': 'boat_page',
        },
        
        {
            'collection': 'manausamazon_info_items',
            'pageCollection': 'manausamazon_info_items',
        },
        {
            'collection': 'manausamazon_amazon_items',
            'pageCollection': 'manausamazon_amazon_items',
        },
        {
            'collection': 'manausamazon_activities',
            'pageCollection': 'manausamazon_activities',
        },
    ];

    for await (const collectionObject of dynamicCollections) {
        const dynamicPageItems = await getDynamicPageItemsRequest(collectionObject.collection, dynamicPageFields);
        if (dynamicPageItems) {
            for await (const item of dynamicPageItems) {
                const matchingTranslation = item.translations.find((translation) => {
                    if (!translation.languages_code) {
                        return false;
                    }

                    return translation.languages_code.code === langCode;
                });

                dynamicPathnames.push({
                    ...item,
                    isStatic: false,
                    isDynamic: true,
                    collection: collectionObject.pageCollection,
                    slug: item.slug,
                    parent_slug: item?.parent_slug ?? null,
                    translated_content: matchingTranslation ?? null,
                });
            }
        }
    }

    return dynamicPathnames;
}
// ---------------- End Pathname Functions ----------------


export const getDynamicItem = async (collection, id) => {
    try {
        const fetchedItems = await Instance.request(readItems(collection, {
            fields: [
                '*'
            ],
            filter: {
                id: id
            },
            limit: 1,
        })) ?? null;
    
        if(fetchedItems) {
            return fetchedItems[0];
        } else {
            console.error('Error fetching dynamic item: Make sure the correct permissions are set for the collection', error);
            return null;
        }
    } catch (error) {
        console.error('Error fetching dynamic item: Make sure the correct permissions are set for the collection', error);
        return null;
    }
}