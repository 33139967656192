import { Instance, getSpecials, getTourCategories, getCruises, getBoats, getManausActivities, getManausInfoItems, getManausAmazonItems } from "./index";
import { unstable_cache } from 'next/cache'
import { readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

// --------------- Start NAvigations --------------
export const getNavigations = async (config = null, locale = 'en', langCode = 'en-US') => {
    const returnObject = {
        footer: null,
        header: null,
    }

    const footer = await getFooterNavigation_cached(langCode);
    const header = await getHeaderNavigation_cached(langCode);
    
    if (footer) {
        footer.translated_content = footer.translations[0];
        delete footer.translations;

        if (footer.translated_content && footer.translated_content.bottombar) {
            for await (const item of footer.translated_content.bottombar) {
                if (item.is_relational_list && item.collection_name) {

                    const subItems = await getRelationalNavigationItems(langCode, item.collection_name);
                    if (subItems) {
                        item.list = Array.from(subItems.map((subItem) => {
                            if(!subItem) {
                                return null;
                            }

                            const link = subItem.slug ? subItem.slug : null;
                            let title = null;
                            if (subItem.translated_content) {
                                title = subItem.translated_content.title;
                            }

                            return {
                                label: title,
                                icon_name: null,
                                url: link,
                            }
                        }));
                    }
                }
            }
        }
        
        returnObject.footer = footer;
    } else {
        returnObject.footer = null;
    }

    if (header) {
        header.translated_content = header.translations[0];
        delete header.translations;

        const menuGenerationClass = new MenuGeneration(config, locale, langCode);
        if (header.translated_content && header.translated_content.topbar) {
            for await (const item of header.translated_content.topbar) {
                if (item.use_generation_function && item.function_name) {
                    const subItems = await menuGenerationClass[`menu_${item.function_name}`]() ?? null;
                    if (subItems) {
                        item.list = subItems.map((subItem, index) => {
                            return {
                                ...subItem,
                                index: index,
                            }
                        });
                    }
                } else if (item.list && item.list.length > 0) {
                    const subItems = await menuGenerationClass[`menu_generateListSubMenu`](item.list) ?? null;
                    if (subItems) {
                        item.list = subItems.map((subItem, index) => {
                            return {
                                ...subItem,
                                index: index,
                            }
                        });
                    }
                }
            }
        }

        returnObject.header = header;
    } else {
        returnObject.header = null;
    }

    return returnObject
}


const getHeaderNavigationRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const header = await Instance.request(readItems('header', {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        },
                    },
                },
                fields: [
                    '*',
                    {
                        translations: [
                            '*'
                        ]
                    }
                ],
                limit: 1,
            })) ?? null;
            resolve(header);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
const getHeaderNavigation_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getHeaderNavigationRequest(langCode);
    },
    ['header_navigation'],
    { revalidate: revalidateTime, tags: ['cached_request', 'header_navigation'] }
);

const getFooterNavigationRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const footer = await Instance.request(readItems('footer', {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        },
                    },
                },
                fields: [
                    '*',
                    {
                        translations: [
                            '*'
                        ]
                    }
                ],
                limit: 1,
            })) ?? null;
            resolve(footer);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
const getFooterNavigation_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getFooterNavigationRequest(langCode);
    },
    ['footer_navigation'],
    { revalidate: revalidateTime, tags: ['cached_request', 'footer_navigation'] }
);


export async function getRelationalNavigationItems(langCode = 'en-US', collectionName = null) {
    const items = await getRelationalNavigationItems_cached(langCode, collectionName);
    return items;
}
const getRelationalNavigationItemsRequest = (langCode = 'en-US', collectionName = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const relationalItems = await Instance.request(readItems(collectionName, {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        },
                    },
                },
                filter: {
                    status: {
                        _eq: 'published',
                    }
                },
                fields: [
                    'id',
                    'slug',
                    {
                        translations: [
                            'title',
                        ],
                    }
                ],
            })) ?? null;

            if (relationalItems) {
                resolve(relationalItems.map((item) => {
                    if (item) {
                        item.translated_content = item.translations[0];
                        delete item.translations;
                    }

                    return item;
                }));
            } else {
                resolve(null);
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getRelationalNavigationItems_cached = unstable_cache(
    async (langCode = 'en-US', collectionName = null) => {
        return await getRelationalNavigationItemsRequest(langCode, collectionName);
    },
    ['relational_navigation_items'],
    { revalidate: revalidateTime, tags: ['cached_request', 'relational_navigation_items'] }
);

export class MenuGeneration {
    constructor(config = null, locale = 'en', langCode = 'en-US') {
        this.config = config;
        this.locale = locale;
        this.langCode = langCode;
    }

    menu_generateListSubMenu = async (list = []) => {
        if (list && list.length > 0) {
            return list.map((item) => {
                return {
                    label: item.label,
                    href: item.url,
                }
            });
        }

        return [];
    }

    menu_generateToursSubMenu = async () => {
        const tours_categories = await getTourCategories(this.langCode, true);
        if(!tours_categories) {
            return null;
        }

        const toursCategoriesList = tours_categories.map((tours_category) => {
            let toursList = [];
            if (tours_category.tours && tours_category.tours.length > 0) {
                toursList = tours_category.tours.map((tour) => {
                    return {
                        label: tour.translated_content.title,
                        href: tour.slug,
                        icon: tour.icon,
                    }
                });
            }

            return {
                label: tours_category.translated_content.title,
                href: tours_category.slug,
                icon: tours_category.icon,
                list: toursList
            }
        });

        return toursCategoriesList;
    }

    menu_generateSpecialsSubMenu = async () => {
        const { pathnamesObject } = this.config;
        const { pathnamesWithInfo } = pathnamesObject;

        const specials = await getSpecials(this.langCode);
        if(!specials) {
            return [];
        }

        const specialsList = specials.map((special) => {
            return {
                label: special.translated_content.title,
                href: special.slug,
                icon: special.icon,
                list: []
            }
        });
        
        const items = [];
        if (pathnamesWithInfo) {
            const specialsPagePathname = Object.values(pathnamesWithInfo).find((pathName) => pathName.collection === 'specials_page');
            if (specialsPagePathname) {
                items.push({
                    label: specialsPagePathname.translated_content.title,
                    href: specialsPagePathname.slug,
                    icon: specialsPagePathname.icon,
                    list: specialsList
                });
            } else {
                specialsList.forEach((special) => {
                    items.push(special);
                });
            }
        } else {
            specialsList.forEach((special) => {
                items.push(special);
            });
        }

        return items;
    }

    menu_generateCruisesSubMenu = async () => {
        const { pathnamesObject } = this.config;
        const { pathnamesWithInfo } = pathnamesObject;

        const cruises = await getCruises(this.langCode);
        const boats = await getBoats(this.langCode);
        
        const cruisesList = cruises.map((cruise) => {
            return {
                label: cruise.translated_content.title,
                href: cruise.slug,
                icon: cruise.icon,
                list: []
            }
        }) ?? [];
        
        const boatsList = boats.map((boat) => {
            return {
                label: boat.translated_content.title,
                href: boat.slug,
                icon: boat.icon,
                list: []
            }
        }) ?? [];
        
        const items = [];
        if (pathnamesWithInfo) {
            const cruisesPagePathname = Object.values(pathnamesWithInfo).find((pathName) => pathName.collection === 'cruises_page');
            const boatsPagePathname = Object.values(pathnamesWithInfo).find((pathName) => pathName.collection === 'boats_page');

            if (cruisesPagePathname) {
                items.push({
                    label: cruisesPagePathname.translated_content?.title ?? '',
                    href: cruisesPagePathname.slug,
                    icon: cruisesPagePathname.icon,
                    list: cruisesList
                });
            } else {
                cruisesList.forEach((cruise) => {
                    items.push(cruise);
                });
            }

            if (boatsPagePathname) {
                items.push({
                    label: boatsPagePathname.translated_content?.title ?? '',
                    href: boatsPagePathname.slug,
                    icon: boatsPagePathname.icon,
                    list: boatsList
                });
            } else {
                boatsList.forEach((boat) => {
                    items.push(boat);
                });
            }
        } else {
            cruisesList.forEach((cruise) => {
                items.push(cruise);
            });

            boatsList.forEach((boat) => {
                items.push(boat);
            });
        }

        return items;
    }

    menu_generateManausAmazonSubMenu = async () => {
        const { pathnamesObject } = this.config;
        const { pathnamesWithInfo } = pathnamesObject;

        const manausActivities = await getManausActivities(this.langCode);
        const manausInfoItems = await getManausInfoItems(this.langCode);
        const manausAmazonItems = await getManausAmazonItems(this.langCode);

        const manausActivitiesList = manausActivities.map((activity) => {
            return {
                label: activity.translated_content?.title ?? '',
                href: activity.slug ?? '',
                icon: activity.icon,
                list: []
            }
        }) ?? [];
        const manausInfoItemsList = manausInfoItems.map((infoItem) => {
            return {
                label: infoItem.translated_content?.title ?? '',
                href: infoItem.slug ?? '',
                icon: infoItem.icon,
                list: []
            }
        }) ?? [];
        const manausAmazonItemsList = manausAmazonItems.map((amazonItem) => {
            return {
                label: amazonItem.translated_content?.title ?? '',
                href: amazonItem.slug ?? '',
                icon: amazonItem.icon,
                list: []
            }
        }) ?? [];

        const items = [];
        if (pathnamesWithInfo) {
            const manausActivitiesPagePathname = Object.values(pathnamesWithInfo).find((pathName) => pathName.collection === 'manausamazon_activities_page');
            const manausInfoPagePathname = Object.values(pathnamesWithInfo).find((pathName) => pathName.collection === 'manausamazon_info_page');
            const manausAmazonPagePathname = Object.values(pathnamesWithInfo).find((pathName) => pathName.collection === 'manausamazon_amazon_page');

            if(manausActivitiesPagePathname){
                items.push({
                    label: manausActivitiesPagePathname.translated_content?.title ?? '',
                    href: manausActivitiesPagePathname.slug ?? '',
                    icon: manausActivitiesPagePathname.icon,
                    list: manausActivitiesList
                });
            } else {
                manausActivitiesList.forEach((activity) => {
                    items.push(activity);
                });
            }
            if(manausInfoPagePathname){
                items.push({
                    label: manausInfoPagePathname.translated_content?.title ?? '',
                    href: manausInfoPagePathname.slug ?? '',
                    icon: manausInfoPagePathname.icon,
                    list: manausInfoItemsList
                });
            } else {
                manausInfoItemsList.forEach((infoItem) => {
                    items.push(infoItem);
                });
            }
            if(manausAmazonPagePathname){
                items.push({
                    label: manausAmazonPagePathname.translated_content?.title ?? '',
                    href: manausAmazonPagePathname.slug ?? '',
                    icon: manausAmazonPagePathname.icon,
                    list: manausAmazonItemsList
                });
            } else {
                manausAmazonItemsList.forEach((amazonItem) => {
                    items.push(amazonItem);
                });
            }

        } else {
            manausActivitiesList.forEach((activity) => {
                items.push(activity);
            });
            manausInfoItemsList.forEach((infoItem) => {
                items.push(infoItem);
            });
            manausAmazonItemsList.forEach((amazonItem) => {
                items.push(amazonItem);
            });
        }
        
        return items;
    }
}

// ---------------- End NAvigations ----------------