import { Instance, getPathnames, createUsablePathnamesObject, getForms, getFormsRequest, getToursData, getCruises } from "./index";
import { getLangCodeByLocale } from "..";
import { unstable_cache } from 'next/cache'
import { readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

// ---------------- Start Languages ----------------
export const getLanguages = async () => {
    const languages = await getLanguages_cached();
    return languages;
}
export const getLanguagesRequest = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const languages = await Instance.request(readItems('languages', {
                single: true,
                filter: {
                    status: {
                        _eq: 'published',
                    }
                },
                fields: [
                    // 'id',
                    'code',
                    'slug_code',
                    'icon_code',
                    'name',
                ],
            }));
            resolve(languages);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
const getLanguages_cached = unstable_cache(
    async () => {
        return await getLanguagesRequest();
    },
    ['languages'],
    { revalidate: revalidateTime, tags: ['cached_request', 'languages'] }
);


export const getMessages = async (langCode = 'en-US') => {
    const messages = await getMessages_cached(langCode);
    return messages;
}
export const getMessagesRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const translations = await Instance.request(readItems('translation_strings', {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        }
                    },
                },
                filter: {
                    status: {
                        _eq: 'published',
                    }
                },
                fields: [
                    '*',
                    {
                        translations: [
                            '*'
                        ]
                    }
                ],
                limit: -1,
            })) ?? null;

            if (translations) {
                const messages = {};
                for await (const translation of translations) {
                    if (translation.translations.length === 0) {
                        continue;
                    }

                    let value = translation.translations[0].value;
                    value = value.replace(/<[^>]*>/g, (match) => {
                        if (match.startsWith('<a') || match.startsWith('</a') ||
                            match.startsWith('<span') || match.startsWith('</span') ||
                            match.startsWith('<strong') || match.startsWith('</strong')
                        ) {
                            return match;
                        } else {
                            return '';
                        }

                    });

                    messages[translation.translation_key] = value;
                }

                resolve(messages);
            } else {
                resolve({});
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
const getMessages_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getMessagesRequest(langCode);
    },
    ['messages'],
    { revalidate: revalidateTime, tags: ['cached_request', 'messages'] }
);

// ---------------- End Languages ----------------

// ---------------- Start Settings ----------------
export const getSettings = async (langCode = 'en-US') => {
    const settings = await getSettings_cached(langCode);

    if (settings && langCode) {
        const settingsTranslation = settings.translations.find((translation) => translation.languages_code.code === langCode);
        if (settingsTranslation) {
            const correctTranslation = settingsTranslation.settings_id.translations.find((translation) => translation.languages_code === langCode);

            if (correctTranslation) {
                settings.translated_content = correctTranslation;

                delete settings.translations;
            }
        }
    }

    return settings;
}
export const getSettingsRequest = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const settings = await Instance.request(readItems('settings', {
                single: true,
                fields: [
                    'whatsapp_phone',
                    'whatsapp_phone_link',
                    'email',
                    'email_link',
                    'default_locale.code',
                    'default_locale.slug_code',
                    'logo_dark',
                    'logo_light',
                    'logo_no_text',
                    'show_icons_in_top_menu',
                    'translations.languages_code.slug_code',
                    'translations.languages_code.code',
                    'translations.settings_id.translations.*',
                    {
                        favicon: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                    }
                ],
            }));
            resolve(settings);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    });
}
const getSettings_cached = unstable_cache(
    async () => {
        return await getSettingsRequest();
    },
    ['settings'],
    { revalidate: revalidateTime, tags: ['cached_request', 'settings'] }
);
// ---------------- End Settings ----------------

// ---------------- Start I18n Config ----------------
export const getConfig_cached = async (locale = 'en') => {
    const languages = await getLanguages();
    const langCode = getLangCodeByLocale(locale, languages);

    const messages = await getMessages(langCode);
    const settings = await getSettings(langCode);
    const forms = await getForms(langCode);
    const pathnames = await getPathnames(false, langCode);

    // getToursData, getCruises

    const toursData = await getToursData(langCode);
    const cruisesData = await getCruises(langCode);


    const pathnamesObject = await createUsablePathnamesObject(pathnames, settings, languages);

    return convertToReadableObject(settings, languages, messages, forms, pathnamesObject, langCode, toursData, cruisesData);
}
export const getConfig = async (locale = 'en') => {
    const languages = await getLanguagesRequest();
    const langCode = getLangCodeByLocale(locale, languages);

    const messages = await getMessagesRequest(langCode);
    const settings = await getSettingsRequest(langCode);
    const forms = await getFormsRequest(langCode);
    const pathnames = await getPathnames(false, langCode);

    const pathnamesObject = await createUsablePathnamesObject(pathnames, settings, languages);

    return convertToReadableObject(settings, languages, messages, forms, pathnamesObject, langCode);
}
const convertToReadableObject = (settings = null, languages = null, messages = null, forms = null, pathnamesObject = null, langCode = null, toursData = null, cruisesData = null) => {
    if (!settings || !languages || !pathnamesObject || !messages || !forms) {
        throw new Error('Settings, languages, messages, forms or pathnames are missing');
    }

    let defaultLocale = 'en';
    let locales = ['en'];

    if (settings.default_locale.code) {
        defaultLocale = settings.default_locale.slug_code;
    }

    if (languages.length > 0) {
        const mappedLocales = languages.map((language) => language.slug_code);
        locales = [...mappedLocales];
    }

    return {
        i18nConfig: {
            defaultLocale,
            localePrefix: 'as-needed',
            locales: locales,
            localeDetection: false,
            pathnames: pathnamesObject.pathnames,
        },
        settings,
        languages,
        messages,
        forms,
        langCode: langCode ?? null,
        pathnamesObject: pathnamesObject ?? null,
        toursData,
        cruisesData,
    }
}
// ---------------- End I18n Config ----------------