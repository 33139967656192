import { Instance } from "./index";
import { unstable_cache } from 'next/cache'
import { readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

export const getCruises = async (langCode = 'en-US') => {
    return await getCruises_cached(langCode);
}
const getCruisesRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const cruises = await Instance.request(readItems('cruises', {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        }
                    }
                },
                filter: {
                    status: {
                        _eq: 'published'
                    },
                },
                fields: [
                    '*',
                    {
                        header_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
                sort: ['sort', 'date_created'],
            })) ?? null;
            
            if (cruises) {
                resolve(cruises.map((cruise) => {
                    if (cruise) {
                        cruise.translated_content = cruise.translations[0];
                        delete cruise.translations;
                    }

                    return cruise;
                }));
            } else {
                resolve(null);
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getCruises_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getCruisesRequest(langCode);
    },
    ['cruises'],
    { revalidate: revalidateTime, tags: ['cached_request', 'cruises'] }
);


export const getBoats = async (langCode = 'en-US') => {
    return await getBoats_cached(langCode);
}
const getBoatsRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const boats = await Instance.request(readItems('boats', {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        }
                    }
                },
                filter: {
                    status: {
                        _eq: 'published'
                    },
                },
                fields: [
                    '*',
                    {
                        header_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
                sort: ['sort', 'date_created'],
            })) ?? null;
            
            if (boats) {
                resolve(boats.map((boat) => {
                    if (boat) {
                        boat.translated_content = boat.translations[0];
                        delete boat.translations;
                    }

                    return boat;
                }));
            } else {
                resolve(null);
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getBoats_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getBoatsRequest(langCode);
    },
    ['boats'],
    { revalidate: revalidateTime, tags: ['cached_request', 'boats'] }
);


export const getCruiseData = async (langCode = 'en-US', cruise_id = null) => {
    return await getCruiseData_cached(langCode, cruise_id);
}
const getCruiseDataRequest = async (langCode = 'en-US', cruise_id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                cruise_parts: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
                seo: {
                    translations: deepLangFilterObj,
                }
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _eq: cruise_id,
                }
            };
    
            const fields = [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                                
                            ]
                        },
                    ],
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                        {
                            image_block_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                        }
                    ],
                    cruise_parts: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                        },
                        {
                            translations: [
                                '*',
                                
                            ]
                        },
                    ],
                },
            ];
    
            const cruises = await Instance.request(readItems('cruises', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!cruises) {
                resolve(null);
            } else {
                const cruise = cruises[0];
    
                if (cruise) {
                    if (cruise.translations) {
                        cruise.translated_content = cruise.translations[0];
                        delete cruise.translations;
                    }
    
                    if (cruise.seo) {
                        cruise.seo.translated_content = cruise.seo.translations[0];
                        delete cruise.seo.translations;
                    }
    
                    if(cruise.cruise_parts) {
                        cruise.cruise_parts.map((cruise_part) => {
                            if (cruise_part.translations) {
                                cruise_part.translated_content = cruise_part.translations[0];
                                delete cruise_part.translations;
                            }
                        });
                    }
    
                    resolve(cruise);
                } else {
                    resolve(null);
                }
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getCruiseData_cached = unstable_cache(
    async (langCode = 'en-US', cruise_id = null) => {
        return await getCruiseDataRequest(langCode, cruise_id);
    },
    ['cruise_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'cruise_data'] }
);


export const getBoatData = async (langCode = 'en-US', boat_id = null) => {
    return await getBoatData_cached(langCode, boat_id);
}
const getBoatDataRequest = async (langCode = 'en-US', boat_id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                content_blocks: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
                seo: {
                    translations: deepLangFilterObj,
                }
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _eq: boat_id,
                }
            };
    
            const fields = [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                                
                            ]
                        },
                    ],
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                    ],
                    content_blocks: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*',
                            ],
                        },
                    ],
                },
            ];
    
            const boats = await Instance.request(readItems('boats', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!boats) {
                resolve(null);
            } else {
                const boat = boats[0];
    
                if (boat) {
                    boat.translated_content = boat.translations[0];
                    delete boat.translations;
    
                    if (boat.seo) {
                        boat.seo.translated_content = boat.seo.translations[0];
                        delete boat.seo.translations;
                    }
    
                    if (boat.content_blocks) {
                        boat.content_blocks.map((content_block) => {
                            content_block.translated_content = content_block.translations[0];
                            delete content_block.translations;
    
                            return content_block;
                        });
                    }
    
                    resolve(boat);
                } else {
                    resolve(null);
                }
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getBoatData_cached = unstable_cache(
    async (langCode = 'en-US', boat_id = null) => {
        return await getBoatDataRequest(langCode, boat_id);
    },
    ['boat_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'boat_data'] }
);


export const getRelatedCruises = async (langCode = 'en-US', cruise_id = null) => {
    return await getRelatedCruises_cached(langCode, cruise_id);
}
const getRelatedCruisesRequest = async (langCode = 'en-US', cruise_id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _neq: cruise_id,
                }
            };
    
            const fields = [
                '*',
                {
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                    ],
                },
            ];
    
            const cruises = await Instance.request(readItems('cruises', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!cruises) {
                resolve(null);
            } else {
                resolve(cruises.map((cruise) => {
                    if (cruise) {
                        cruise.translated_content = cruise.translations[0];
                        delete cruise.translations;
                    }
    
                    return cruise;
                }));
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getRelatedCruises_cached = unstable_cache(
    async (langCode = 'en-US', cruise_id = null) => {
        return await getRelatedCruisesRequest(langCode, cruise_id);
    },
    ['related_cruises'],
    { revalidate: revalidateTime, tags: ['cached_request', 'related_cruises'] }
);