import { getDynamicItem } from './directus';

export * from './directus';

export const getLangCodeByLocale = (locale = 'en', languages = []) => {
    const langCode = languages.find((lang) => lang.slug_code === locale)?.code;
    return langCode;
}
    
export function getPageExistFunction(config = null, locale = 'en') {
    if (!config) {
        console.error('No config provided to getPageExistFunction', config, typeof config, locale);
        throw new Error('No config provided to getPageExistFunction');
    }
    const {
        pathnamesObject = null,
        settings = null,
        languages = null,
        i18nConfig
    } = config;

    const { locales = ['en'], defaultLocale = 'en' } = i18nConfig;

    if (!pathnamesObject || !settings || !languages || locales.length === 0 || defaultLocale === null) {
        console.error('Make sure to provide all needed parameters to getPageExistFunction:', {
            pathnamesObject: !pathnamesObject,
            settings: !settings,
            languages: !languages,
            locales: locales.length === 0,
            defaultLocale: defaultLocale === null,
        });
        throw new Error('Make sure to provide all needed parameters to getPageExistFunction');
    }

    const currentLocale = locale ?? defaultLocale;
    
    const { pathnamesWithInfo } = pathnamesObject;
    const pathnamesWithInfoArray = Object.values(pathnamesWithInfo);
    
    return async (pageSlug = null) => {
        if (pageSlug === null) {
            return false;
        }

        // make sure pageSlug starts with /
        if (!pageSlug.startsWith('/')) {
            pageSlug = `/${pageSlug}`;
        }

        const foundPathname = pathnamesWithInfoArray.find((path) => {
            return path.slug === pageSlug;
        });

        if (!foundPathname) {
            console.warn(`${locale} (${pageSlug}) does not exist...`);

            const pathsWithBrackets = pathnamesWithInfoArray.filter((path) => {
                return path.pathnames[currentLocale].includes('[') && path.pathnames[currentLocale].includes(']');
            });

            let foundPathWithBrackets = null;
            let extractedParams = {};

            if (pathsWithBrackets.length > 0) {
                console.log(`No path found for ${pageSlug} but maybe with brackets?`);

                for (const pathWithBrackets of pathsWithBrackets) {
                    // Convert bracketed route to regex
                    const regexPattern = pathWithBrackets.pathnames[currentLocale]
                        .replace(/\[.*?\]/g, '([^/]+)') // Replace [param] with regex group
                        .replace(/\//g, '\\/'); // Escape slashes for regex
                        
                        const routeRegex = new RegExp(`^${regexPattern}$`);
                    const match = pageSlug.match(routeRegex);
                    if (match) {
                        foundPathWithBrackets = pathWithBrackets;
                        // Extract dynamic value
                        const paramName = pathWithBrackets.pathnames[currentLocale].match(/\[(.*?)\]/)[1]; // Get param name inside brackets
                        extractedParams[paramName] = match[1]; // Assign value from matched group
                        break;
                    }
                }
            }

            if (foundPathWithBrackets) {
                foundPathWithBrackets.dynamic_params = extractedParams;

                if (extractedParams) {
                    for (const [key, value] of Object.entries(extractedParams)) {
                        const abstractedKey = key.replace('_id', 's');
                        const item = await getDynamicItem(abstractedKey, value);
                        if (!item) {
                            return false;
                        }
                    }
                }

                console.log(`${locale} (${pageSlug}) dynamic params found...`);
                
                return foundPathWithBrackets;
            } else {
                console.log("No matching dynamic route found.");
                return false;
            }
        }

        console.log(`${locale} (${pageSlug}) found...`);
        return foundPathname;
    }
}