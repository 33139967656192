
export const getFileUrl = (fileID) => {
    if (typeof fileID === 'object') {
        fileID = fileID.id;
    }

    return `${process.env.NEXT_APP_DIRECTUS_URL}/assets/${fileID}`;
}

export function getRouteFunctions(config = null, locale = 'en') {
    if (!config) {
        console.error('No config provided to getRouteFunctions', config, typeof config, locale);
        throw new Error('No config provided to getRouteFunctions');
    }
    const {
        pathnamesObject = null,
        settings = null,
        languages = null,
        i18nConfig
    } = config;

    const { locales = ['en'], defaultLocale = 'en' } = i18nConfig;

    if (!pathnamesObject || !settings || !languages || locales.length === 0 || defaultLocale === null) {
        console.error('Make sure to provide all needed parameters to getRouteFunctions:', {
            pathnamesObject: !pathnamesObject,
            settings: !settings,
            languages: !languages,
            locales: locales.length === 0,
            defaultLocale: defaultLocale === null,
        });
        throw new Error('Make sure to provide all needed parameters to getRouteFunctions');
    }

    const currentLocale = locale ?? defaultLocale;
    
    const { pathnamesWithInfo } = pathnamesObject;
    const pathnamesWithInfoArray = Object.values(pathnamesWithInfo);

    function route(href, suffix = '') {
        let pathname = href ?? null;

        if(pathname === null) {
            return href;
        }

        let regex = new RegExp(`/${currentLocale}`, 'g');
        for (const locale of locales) {
            regex = new RegExp(`/${locale}`, 'g');
            pathname = pathname.replace(regex, '');
        }

        pathname = pathname.replace(/\/$/, '');
        if (pathname === '') {
            pathname = '/';
        }
        
        let foundPath = pathnamesWithInfoArray.find((path) => {
            return path.pathnames[defaultLocale] === pathname;
        });

        if (!foundPath) {
            console.error(`route(${href}) path not found default (${defaultLocale})`);

            foundPath = pathnamesWithInfoArray.find((path) => {
                return path.pathnames[currentLocale] === pathname;
            });
    
            if (!foundPath) {
                console.error(`route(${href}) path not found in ${currentLocale}`);
                return href;
            }
        }

        if (locale == defaultLocale) {
            return `${foundPath.pathnames[defaultLocale]}${suffix ?? ''}`;
        } else {
            return `/${locale}${foundPath.pathnames[locale]}${suffix ?? ''}`;
        }
    }

    function replacePathsWithRoutes (text) {
        const pattern = /href="(.*?)"/g;
        try {
            return text.replace(pattern, (match, path) => {
                if(path.startsWith('/')) {
                    return match.replace(path, route(path));
                } else {
                    return match;
                }
            });
        } catch (error) {
            console.error('Error replacing paths with routes', error);
            return text;
        }
    };

    return {
        route,
        replacePathsWithRoutes,
    }
}

export function getTranslationFunction(config = null, locale = 'en') {
    if (!config) {
        console.error('No config provided to getTranslationFunction', config, typeof config, locale);
        throw new Error('No config provided to getTranslationFunction');
    }

    const { settings, languages, messages } = config;

    if (!settings || !languages || !messages) {
        console.error('Make sure to provide all needed parameters to getTranslationFunction:', {
            settings: !settings,
            languages: !languages,
            messages: !messages,
        });
        throw new Error('Make sure to provide all needed parameters to getTranslationFunction');
    }
    
    return {
        t: (key) => {
            const translation = messages[key] ?? null;

            if (!translation) {
                console.warn(`${locale} translation for key ${key} not found`);
                return key;
            }

            return translation;
        }
    }
}

export function getMetaGenerator(config = null, locale = 'en') {
    const baseUrl = process.env.NEXT_APP_URL;
    const { settings, i18nConfig } = config;

    if (!settings || !locale || !i18nConfig) {
        console.warn('No settings, i18nConfig or locale provided to getMetaGenerator', { settings, locale, i18nConfig });
        return null;
    }

    const { defaultLocale = 'en' } = i18nConfig;

    async function generateMetadataObject(pageData = {}, isErrorPage = false) {
        const pathnames = pageData.pathnames;

        let title = 'Welcome to Amazon Tour Manaus';
        let description = 'Amazon Tour Manaus is a tour company that offers tours of the Amazon rainforest.';
        let icons = {};
        if (settings.favicon) {
            if (settings.favicon.id) {
                icons.icon = getFileUrl(settings.favicon.id);
            }
        }
        
        let titleAppend = '';

        if(settings && settings.translated_content) {
            if(settings.translated_content.default_title) {
                title = settings.translated_content.default_title;
            }
            
            if(settings.translated_content.title_append) {
                titleAppend = settings.translated_content.title_append;
            }

            if(settings.translated_content.default_description) {
                description = settings.translated_content.default_description;
            }
        }


        if(isErrorPage) {
            title = 'Page not found';
            description = 'The page you are looking for does not exist.';
        }

        if (pageData && pageData.seo) {
            if (pageData.seo.translated_content) {
                const translatedContent = pageData.seo.translated_content;

                if (translatedContent.title) {
                    title = translatedContent.title;
                }

                if (translatedContent.description) {
                    description = translatedContent.description;
                }
            }
        }

        const alternates = {
            languages: {},
        };

        if (pathnames) {
            for await (const [key, value] of Object.entries(pathnames)) {
                if (key === defaultLocale) {
                    alternates.languages['x-default'] = baseUrl + value;
                }
                alternates.languages[key] = baseUrl + value;
            }
        } else {
            console.error('Cannot generate alternates for page', pageData.collection);
        }
        
        const generatedMetaObject = {
            title: title + titleAppend,
            description: description,
            alternates: alternates,
            openGraph: {
                title: title + titleAppend,
                description: description,
            },
            icons: icons,
        }

        return generatedMetaObject;
    }

    return generateMetadataObject;
}

export function getBreadcrumbObject(pathnamesWithInfo = null, currentPathname = null) {
    const breadcrumbObject = {};

    if (!pathnamesWithInfo) {
        console.warn('getBreadcrumbObject - No pathnamesWithInfo provided');
        return breadcrumbObject;
    }

    if (!currentPathname) {
        console.warn('getBreadcrumbObject - No currentPathname provided');
        return breadcrumbObject;
    }

    let currentPath = currentPathname.slug;
    let last = false;
    
    while (!last) {
        const currentPathnameObject = pathnamesWithInfo[currentPath];

        if (currentPathnameObject) {
            breadcrumbObject[currentPath] = currentPathnameObject;
            currentPath = currentPathnameObject.parent_slug ?? '/';
        } else {
            currentPath = '/';
        }

        if (typeof currentPathnameObject === 'undefined') {
            last = true;
        } else if (currentPathnameObject === null) {
            last = true;
        } else if (typeof currentPathnameObject !== 'undefined' && currentPathnameObject && !currentPathnameObject.parent_slug) {
            last = true;
        } else {
        }
    }

    const reversedBreadcrumbObject = {};
    Object.keys(breadcrumbObject).reverse().forEach(key => {
        reversedBreadcrumbObject[key] = breadcrumbObject[key];
    });

    return reversedBreadcrumbObject;
}

export function getFormFetcher(config = null, locale = 'en') {
    if (!config) {
        console.error('No config provided to getFormFetcher', config, typeof config, locale);
        throw new Error('No config provided to getFormFetcher');
    }

    const { forms } = config;

    if (!forms) {
        console.error('No forms provided to getFormFetcher', config, typeof config, locale);
        throw new Error('No forms provided to getFormFetcher');
    }
    
    return {
        getFormByKey: (formKey) => {
            const form = forms.find((form) => form.key === formKey);

            if (!form) {
                console.error('Form not found', formKey);
                return null;
            }
            return form;
        }
    }
}
