import { createDirectus, rest, staticToken } from '@directus/sdk';

export const Instance = createDirectus(process.env.NEXT_APP_DIRECTUS_URL).with(
    rest({
        onRequest: (options) => ({
            ...options,
            cache: 'force-cache'
        }),
    })
);

export const GetAdminInstance = (token) => {
    return createDirectus(process.env.NEXT_APP_DIRECTUS_URL)
        .with(
            rest({
                onRequest: (options) => ({
                    ...options,
                    cache: 'no-store',
                }),
            })
        )
        .with(staticToken(token));
}