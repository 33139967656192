'use client';

import { getTranslationFunction } from '@/app/utils/mixed_csr_ssr';
import { useSearchParams } from 'next/navigation';

import Typography from '@/components/shared/Typography';

export default function HeadingSectionTextWrapper({
    params,
    title,
    subtitle
}) {
    const { config, locale } = params;
    const { t } = getTranslationFunction(config, locale);


    const searchParams = useSearchParams();
    const orderSuccess = searchParams.get('success');

    if (orderSuccess !== null) {
        const isSuccess = orderSuccess === 'true';
        subtitle = '';
        if (isSuccess) {
            title = t('booking_success');
        } else {
            title = t('booking_failed');
        }
    }

    return (
        <>
            {title && (
                <Typography variant='h1' hasShadow={true} className='text-white'>
                    {title}
                </Typography>
            )}

            {subtitle && (
                <Typography variant='h2' styleVariant={'h4'} hasShadow={true} className='text-white pt-4'>
                    {subtitle}
                </Typography>
            )}
        </>
    )
}