import { Instance } from "./index";
import { unstable_cache } from 'next/cache'
import { readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

export const getTourCategories = async (langCode = 'en-US', withChildren = false) => {
    return await getTourCategories_cached(langCode, withChildren);
}
const getTourCategoriesRequest = async (langCode = 'en-US', withChildren = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepFilterObject = {
                translations: {
                    _filter: {
                        _and: [
                            {
                                languages_code: { _eq: langCode },
                            },
                        ],
                    }
                }
            }

            const fields = [
                '*',
                {
                    translations: [
                        '*'
                    ],
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                }
            ];

            if (withChildren) {
                deepFilterObject.tours = {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        }
                    },
                    status: {
                        _eq: 'published',
                    }
                };

                fields.push({
                    tours: [
                        '*',
                        {
                            translations: [
                                '*'
                            ]
                        }
                    ]
                });
            }

            const tours_categories = await Instance.request(readItems('tours_categories', {
                deep: deepFilterObject,
                filter: {
                    status: {
                        _eq: 'published'
                    },
                },
                fields: fields,
                sort: ['sort', 'date_created'],
            })) ?? null;

            if (tours_categories) {
                resolve(tours_categories.map((tours_category) => {
                    if (tours_category) {
                        tours_category.translated_content = tours_category.translations[0];
                        delete tours_category.translations;
                    }
                    
                    if (withChildren) {
                        if (tours_category.tours && tours_category.tours.length > 0) {
                            tours_category.tours = tours_category.tours.map((tour) => {
                                tour.translated_content = tour.translations[0];
                                delete tour.translations;

                                return tour;
                            });
                        }
                    }

                    return tours_category;
                }));
            } else {
                resolve(null);
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getTourCategories_cached = unstable_cache(
    async (langCode = 'en-US', withChildren = false) => {
        return await getTourCategoriesRequest(langCode, withChildren);
    },
    ['tour_categories'],
    { revalidate: revalidateTime, tags: ['cached_request', 'tour_categories'] }
);


export const getTourCategoryData = async (langCode = 'en-US', tours_categories_id = null) => {
    return await getTourCategoryData_cached(langCode, tours_categories_id);
}
const getTourCategoryDataRequest = async (langCode = 'en-US', tours_categories_id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const withChildren = true;

            const deepLangFilterObj = {
                translations: {
                    _filter: {
                        _and: [
                            {
                                languages_code: { _eq: langCode },
                            },
                        ],
                    }
                }
            }

            const deepFilterObject = {
                translations: deepLangFilterObj,
                information_blocks: {
                    translations: deepLangFilterObj,
                    status: {
                        _eq: 'published',
                    }
                },
                seo: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
            };
            if (withChildren) {
                deepFilterObject.tours = {
                    translations: deepLangFilterObj,
                    status: {
                        _eq: 'published',
                    }
                };
            }
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _eq: tours_categories_id,
                }
            };
    
            const fields = [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                            ]
                        }
                    ],
                },
                {
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*'
                    ],
                    information_blocks: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*'
                            ]
                        }
                    ],
                },
            ];
            if (withChildren) {
                fields.push({
                    tours: [
                        '*',
                        {
                            header_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                            translations: [
                                '*'
                            ]
                        }
                    ]
                });
            }

            const tours_categories = await Instance.request(readItems('tours_categories', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!tours_categories) {
                resolve(null);
            } else {
                const tours_category = tours_categories[0];
    
                if (tours_category) {
                    if (tours_category.translations) {
                        tours_category.translated_content = tours_category.translations[0];
                        delete tours_category.translations;
                    }
    
                    if (tours_category.seo) {
                        tours_category.seo.translated_content = tours_category.seo.translations[0];
                        delete tours_category.seo.translations;
                    }
    
                    if (tours_category.information_blocks) {
                        tours_category.information_blocks = tours_category.information_blocks.map((information_block) => {
                            if (information_block.translations) {
                                information_block.translated_content = information_block.translations[0];
                                delete information_block.translations;
                            }
    
                            return information_block;
                        });
                    }
    
                    if (tours_category.tours) {
                        tours_category.tours = tours_category.tours.map((tour) => {
                            if (tour.translations) {
                                tour.translated_content = tour.translations[0];
                                delete tour.translations;
                            }
    
                            return tour;
                        });
                    }
    
                    resolve(tours_category);
                } else {
                    resolve(null);
                }
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getTourCategoryData_cached = unstable_cache(
    async (langCode = 'en-US', tours_categories_id = null) => {
        return await getTourCategoryDataRequest(langCode, tours_categories_id);
    },
    ['tour_category_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'tour_category_data'] }
);


export const getTourData = async (langCode = 'en-US', tour_id = null) => {
    return await getTourData_cached(langCode, tour_id);
}
const getTourDataRequest = async (langCode = 'en-US', tour_id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                seo: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
                tour_parts: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _eq: tour_id,
                }
            };
    
            const fields = [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                            ]
                        }
                    ],
                },
                {
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                        {
                            image_block_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                        }
                    ],
                    tour_parts: [
                        '*',
                        {
                            media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                        },
                        {
                            translations: [
                                '*',
                                
                            ]
                        },
                    ]
                },
            ];

            const tours = await Instance.request(readItems('tours', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!tours) {
                resolve(null);
            } else {
                const tour = tours[0];
    
                if (tour) {
                    if (tour.translations) {
                        tour.translated_content = tour.translations[0];
                        delete tour.translations;
                    }
    
                    if (tour.seo) {
                        tour.seo.translated_content = tour.seo.translations[0];
                        delete tour.seo.translations;
                    }
    
                    if(tour.tour_parts) {
                        tour.tour_parts.map((tour_part) => {
                            if (tour_part.translations) {
                                tour_part.translated_content = tour_part.translations[0];
                                delete tour_part.translations;
                            }
                        });
                    }
    
                    resolve(tour);
                } else {
                    resolve(null);
                }
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getTourData_cached = unstable_cache(
    async (langCode = 'en-US', tour_id = null) => {
        return await getTourDataRequest(langCode, tour_id);
    },
    ['tour_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'tour_data'] }
);


export const getToursData = async (langCode = 'en-US') => {
    return await getToursData_cached(langCode);
}
const getToursDataRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: { _eq: langCode },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                seo: {
                    status: {
                        _eq: 'published',
                    },
                    translations: deepLangFilterObj,
                },
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                }
            };
    
            const fields = [
                '*',
                {
                    seo: [
                        '*',
                        {
                            translations: [
                                '*',
                            ]
                        }
                    ],
                },
                {
                    header_media: [
                        'id',
                        'folder',
                        'type',
                        'title',
                        'filename_disk',
                        'filename_download',
                        'width',
                        'height',
                        'duration',
                    ],
                    translations: [
                        '*',
                        {
                            image_block_media: [
                                'id',
                                'folder',
                                'type',
                                'title',
                                'filename_disk',
                                'filename_download',
                                'width',
                                'height',
                                'duration',
                            ],
                        }
                    ],
                },
            ];
    
            const tours = await Instance.request(readItems('tours', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!tours) {
                resolve(null);
            } else {
                resolve(tours.map((tour) => {
                    if (tour) {
                        if (tour.translations) {
                            tour.translated_content = tour.translations[0];
                            delete tour.translations;
                        }
    
                        if (tour.seo) {
                            tour.seo.translated_content = tour.seo.translations[0];
                            delete tour.seo.translations;
                        }
    
                        if(tour.tour_parts) {
                            tour.tour_parts.map((tour_part) => {
                                if (tour_part.translations) {
                                    tour_part.translated_content = tour_part.translations[0];
                                    delete tour_part.translations;
                                }
                            });
                        }
        
                        return tour;
                    } else {
                        return null;
                    }
                }));
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getToursData_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getToursDataRequest(langCode);
    },
    ['tours_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'tours_data'] }
);


export const getRelatedTours = async (langCode = 'en-US', tours_categories_id = null, tour_id = null) => {
    return await getRelatedTours_cached(langCode, tours_categories_id, tour_id);
}
const getRelatedToursRequest = async (langCode = 'en-US', tours_categories_id = null, tour_id = null) => {
    return new Promise(async (resolve, reject) => {
        try {
            const deepLangFilterObj = {
                _filter: {
                    _and: [
                        {
                            languages_code: {
                                _eq: langCode
                            },
                        },
                    ],
                },
            };
    
            const deepFilterObject = {
                translations: deepLangFilterObj,
                _filter: {
                    tours_categories: {
                        id: {
                            _eq: tours_categories_id
                        },
                        translations: deepLangFilterObj,
                    },
                }
            };
    
            const filterObject = {
                status: {
                    _eq: 'published',
                },
                id: {
                    _neq: tour_id,
                }
            };
    
            const fields = [
                '*',
                {
                    translations: [
                        '*'
                    ]
                },
            ];
    
            const related_tours = await Instance.request(readItems('tours', {
                sort: ['sort', 'date_created'],
                deep: deepFilterObject,
                filter: filterObject,
                fields: fields
            }));
    
            if (!related_tours) {
                resolve(null);
            } else {
                resolve(related_tours.map((related_tour) => {
                    if (related_tour) {
                        related_tour.translated_content = related_tour.translations[0];
                        delete related_tour.translations;
                    }
    
                    return related_tour;
                }));
            }
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getRelatedTours_cached = unstable_cache(
    async (langCode = 'en-US', tours_categories_id = null, tour_id = null) => {
        return await getRelatedToursRequest(langCode, tours_categories_id, tour_id);
    },
    ['related_tours'],
    { revalidate: revalidateTime, tags: ['cached_request', 'related_tours'] }
);