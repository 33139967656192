'use client';

import { getRouteFunctions } from '@/app/utils/mixed_csr_ssr';

export default function LangSwitcher({ params }) {

    const { config, locale } = params;
    const { languages } = config;

    
    const handleLangChange = async (e) => {
        const { route } = getRouteFunctions(config, e.target.value);
        const newRoute = route('/')
        window.location = newRoute
    }
	
    const activeLang = languages.find((lang) => lang.slug_code === params.locale)
    

    return (
        <>
            <div className='flex w-fit gap-x-1 items-center'>
                <div className={`h-4 aspect-video bg-transparent fi fi-${activeLang.icon_code}`}></div>
                <select defaultValue={params.locale} className='py-2 text-white font-bold font-overpass bg-transparent text-shadow-sm shadow-black outline-none' onChange={handleLangChange}>
                    {languages.map((language, index) => (
                        <option key={index} className='text-primary-800' value={language.slug_code}>
                            {language.name}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}