import { Instance } from "./index";
import { unstable_cache } from 'next/cache'
import { readItems } from '@directus/sdk';
import { revalidateTime } from "@/app/utils/constants";

export const getSpecials = async (langCode = 'en-US') => {
    return await getSpecials_cached(langCode);
}
const getSpecialsRequest = async (langCode = 'en-US') => {
    return new Promise(async (resolve, reject) => {
        try {
            const specials = await Instance.request(readItems('specials_page', {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: langCode },
                                },
                            ],
                        }
                    }
                },
                filter: {
                    status: {
                        _eq: 'published'
                    },
                },
                fields: [
                    '*',
                    {
                        translations: [
                            '*',
                            'languages_code.slug_code',
                        ],
                        special_tours: [
                            '*',
                            {
                                translations: [
                                    '*',
                                    'languages_code.slug_code',
                                ]
                            }
                        ],
                    }
                ],
                sort: ['date_created'],
            })) ?? null;
            
            if (specials && specials.special_tours) {
                resolve(specials.special_tours.map((special_tour) => {
                    if (special_tour) {
                        special_tour.translated_content = special_tour.translations[0];
                        delete special_tour.translations;
                    }

                    return special_tour;
                }));
            }

            resolve(specials);
        } catch (error) {
            console.error('SDK Client Error: ', error.errors);
            reject(error);
        }
    })
}
const getSpecials_cached = unstable_cache(
    async (langCode = 'en-US') => {
        return await getSpecialsRequest(langCode);
    },
    ['specials'],
    { revalidate: revalidateTime, tags: ['cached_request', 'specials'] }
);
