'use client'

import React from 'react'
import { clsx } from 'clsx';

export default function Textarea({ placeholder, rows = 1, onChange, name, id, className, style, ...rest }) {
	return (
		<>
			<textarea
				placeholder={placeholder}
				rows={rows}
				onChange={onChange}
				name={name}
				id={id}
				className={clsx('font-overpass text-base mb-1 py-1 px-2 bg-transparent outline-none text-secondary-100 placeholder:text-secondary-100 placeholder:opacity-50 flex-1', className)}
				style={style}
				{...rest}
			></textarea>
		</>
	)
}
