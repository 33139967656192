import { Instance } from "./index";
import { readItems } from '@directus/sdk';
import { unstable_cache } from 'next/cache'
import { getTourCategoryData, getTourData, getCruiseData, getBoatData, getManausActivityData, getManausInfoItemData, getManausAmazonItemData } from './index';
import { revalidateTime } from "@/app/utils/constants";

export async function getPageData(params = {
    isDynamic: false,
    locale: 'en',
    langCode: 'en-US',
    pageSlug: '',
    collection: '',
    id: '',
}) {
    const { isDynamic, locale, langCode, pageSlug, collection, id } = params;

    console.log(`Getting ${isDynamic ? 'dynamic' : 'static'} page data for /${pageSlug} in '${locale}' with langCode '${langCode}', collection '${collection}' and id '${id}'`);
    
    let pageData = null;
    if (isDynamic) {
        pageData = await getDynamicPageData_cached(collection, id, langCode);
    } else {
        pageData = await getStaticPageData_cached(collection, langCode);
    }

    return pageData;
}

const getStaticPageData_cached = unstable_cache(
    async (collection, langCode) => {
        return await getStaticPageDataRequest(collection, langCode);
    },
    ['static_page_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'static_page_data'] }
);
const getStaticPageDataRequest = async (collection, langCode) => {
    const deepLangFilterObj = {
        _filter: {
            _and: [
                {
                    languages_code: { _eq: langCode },
                },
            ],
        },
    };

    const pageData = await Instance.request(readItems(collection, {
        deep: {
            translations: deepLangFilterObj,
            seo: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            cards: {
                translations: deepLangFilterObj,
            },
            information_blocks: {
                translations: deepLangFilterObj,
                status: {
                    _eq: 'published',
                }
            },
            content_blocks: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            special_tours: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
            boats: {
                status: {
                    _eq: 'published',
                },
                translations: deepLangFilterObj,
            },
        },
        fields: [
            '*',
            {
                seo: [
                    '*',
                    {
                        translations: [
                            '*',
                        ]
                    }
                ],
            },
            {
                header_media: [
                    'id',
                    'folder',
                    'type',
                    'title',
                    'filename_disk',
                    'filename_download',
                    'width',
                    'height',
                    'duration',
                ],
            },
            {
                translations: [
                    '*',
                    // '*.our_details',
                    // '*.other_pages',
                    // '*.license_tourist_agency',
                    // '*.faq_items',
                    // '*.fundamentals',
                    // '*.text_blocks',
                ]
            },
            {
                cards: [
                    '*',
                    {
                        header_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*'
                        ]
                    }
                ],
                information_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*'
                        ]
                    }
                ],
                content_blocks: [
                    '*',
                    {
                        media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ],
                    },
                ],
                special_tours: [
                    '*',
                    {
                        header_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ]
                    }
                ],
                boats: [
                    '*',
                    {
                        header_media: [
                            'id',
                            'folder',
                            'type',
                            'title',
                            'filename_disk',
                            'filename_download',
                            'width',
                            'height',
                            'duration',
                        ],
                        translations: [
                            '*',
                        ]
                    }
                ],
            },
        ],
        limit: 1,
    })) ?? null;

    if (pageData) {
        pageData.collection = collection;
        
        if (pageData.translations) {
            pageData.translated_content = pageData.translations[0];
            delete pageData.translations;
        } else {
            console.warn(`No translations found for page ${collection} with id ${pageData.id}`);
            
        }

        if (pageData.cards) {
            pageData.cards.forEach((card) => {
                card.translated_content = card.translations[0];
            });
        }

        if (pageData.information_blocks) {
            pageData.information_blocks.forEach((information_block) => {
                information_block.translated_content = information_block.translations[0];
                delete information_block.translations;
            });
        }

        if (pageData.content_blocks) {
            pageData.content_blocks.map((content_block) => {
                content_block.translated_content = content_block.translations[0];
                delete content_block.translations;
            });
        }

        if (pageData.special_tours) {
            pageData.special_tours.forEach((special_tour) => {
                special_tour.translated_content = special_tour.translations[0];
                delete special_tour.translations;
            });
        }

        if (pageData.seo) {
            pageData.seo.translated_content = pageData.seo.translations[0];
            delete pageData.seo.translations;
        }

        if (pageData.boats) {
            pageData.boats.forEach((boat) => {
                boat.translated_content = boat.translations[0];
                delete boat.translations;
            });
        }

        return pageData;
    }

    return null;
}

const getDynamicPageData_cached = unstable_cache(
    async (collection, id, langCode) => {
        return await getDynamicPageDataRequest(collection, id, langCode);
    },
    ['dynamic_page_data'],
    { revalidate: revalidateTime, tags: ['cached_request', 'dynamic_page_data'] }
);
const getDynamicPageDataRequest = async (collection, id, langCode) => {
    if(!id || !collection || !langCode) {
        console.error('No id, collection, or langCode provided');
        return null;
    }

    let dynamicPageData = null;
    let originalCollectionName = null;
    switch (collection) {
        case 'tours_category_page':
            dynamicPageData = await getTourCategoryData(langCode, id);
            originalCollectionName = 'tours_categories';
            break;
        case 'tour_page':
            dynamicPageData = await getTourData(langCode, id);
            originalCollectionName = 'tours';
            break;
        case 'cruise_page':
            dynamicPageData = await getCruiseData(langCode, id);
            originalCollectionName = 'cruises';
            break;
        case 'boat_page':
            dynamicPageData = await getBoatData(langCode, id);
            originalCollectionName = 'boats';
            break;
        case 'manausamazon_activities':
            dynamicPageData = await getManausActivityData(langCode, id);
            originalCollectionName = 'manausamazon_activities';
            break;
        case 'manausamazon_info_items':
            dynamicPageData = await getManausInfoItemData(langCode, id);
            originalCollectionName = 'manausamazon_info_items';
            break;
        case 'manausamazon_amazon_items':
            dynamicPageData = await getManausAmazonItemData(langCode, id);
            originalCollectionName = 'manausamazon_amazon_items';
            break;
        default:
            console.error(`No dynamic page data found for collection ${collection} with id ${id}`);
            break;
    }

    dynamicPageData.collection = originalCollectionName;
    // console.log('Original collection name', originalCollectionName);

    return dynamicPageData;
}