'use client';

import React, { useState } from 'react';

import Collapsable from '@/components/shared/Collapsable';

const FAQHeading = ({ pageData }) => {
    const [active, setActive] = useState(0);

    return (
        <div className='w-container mx-auto mt-20'>
            <div className='w-11/12 mx-auto flex flex-col items-center gap-3'>
                {pageData.translated_content && pageData.translated_content.faq_items && pageData.translated_content.faq_items.map((item, index) => (
                    <div key={index} className='w-full'>
                        <Collapsable index={index} active={active} setActive={setActive} title={item.title} text={item.text} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQHeading